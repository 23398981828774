<template>
  <div class="column q-pa-lg q-gutter-lg">
    <div class="col col-12 row justify-end">
      <q-btn color="per" size="sm" outline @click.native="exportComptageDetails(date_projetee_2)">{{$t('cibles.detailled_export')}}</q-btn>
      <q-btn class="q-ml-md" v-if="date_projetee_2" color="per" size="sm" outline @click.native="exportComptage()">{{$t('cibles.export_evolution')}}</q-btn>
    </div>

    <div class="col">
      <div class="row q-gutter-md">
        <q-list class="col" bordered>
          <q-item class="title-item"><q-item-section><q-item-label>TYPE</q-item-label></q-item-section></q-item>

          <q-item v-for="(column, index) in columns" v-bind:key="index"
            v-bind:class="{ 'title-item': isTitleItem(column) }" :dense="!isTitleItem(column)">
            <q-item-section>
              <q-item-label>{{!isTitleItem(column) ? $t(`per.comptage.${column}.label`) : $t(`per.comptage.${column}.label`).toUpperCase()}}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-list class="col" bordered>
          <q-item class="title-item">
            <q-item-section class="q-pa-none">
              <base-input-date v-model="date_projetee_1" color="per" dense />
            </q-item-section>
          </q-item>

          <q-item v-for="(column, index) in columns" v-bind:key="index"
            v-bind:class="{ 'title-item': isTitleItem(column) }" :dense="!isTitleItem(column)">
            <q-item-section>
              <q-item-label>{{comptage_1 ? comptage_1[column] : '-'}}</q-item-label>
            </q-item-section>
          </q-item>

          <!-- <q-item>
            <q-item-section v-if="date_projetee_1">
              <q-btn color="per" size="sm" outline @click.native="exportComptageDetails(date_projetee_1)">{{$t('cibles.export')}}</q-btn>
            </q-item-section>
          </q-item> -->
        </q-list>

        <q-list class="col" bordered>
          <q-item class="title-item">
            <q-item-section>
              <base-input-date v-model="date_projetee_2" color="per" dense />
            </q-item-section>
          </q-item>

          <q-item v-for="(column, index) in columns" v-bind:key="index"
            v-bind:class="{ 'title-item': isTitleItem(column) }" :dense="!isTitleItem(column)">
            <q-item-section>
              <q-item-label>{{comptage_2 ? comptage_2[column] : '-'}}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <q-list class="col" bordered>
          <q-item class="title-item"><q-item-section><q-item-label>EVOLUTION</q-item-label></q-item-section></q-item>

          <q-item v-for="(column, index) in columns" v-bind:key="index"
            v-bind:class="{ 'title-item': isTitleItem(column) }" :dense="!isTitleItem(column)">
            <q-item-section :set="diff = getComptageDiff(column)">
              <q-item-label :class="diff < 0 ? 'text-negative' : (diff > 0 ? 'text-positive' : 'text-grey-6')">
                <q-icon v-if="diff !== '-'" :name="diff < 0 ? 'arrow_downward' : (diff > 0 ? 'arrow_upward' : 'arrow_forward')" />
                <span>{{diff}}</span>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </div>
  </div>
</template>

<script>
import download from 'downloadjs'

export default {
  name: 'ComptageReseauPage',

  data () {
    return {
      date_projetee_1: null,
      date_projetee_2: null,
      // date_projetee_1: this.$formatDate(new Date(), 'YYYY-MM-DD'),
      // date_projetee_2: this.$formatDate(new Date(), 'YYYY-MM-DD'),
      comptage_1: null,
      comptage_2: null,
      columns: [
        'entites', 'entites_mono_agent', 'entites_multi_agents', 'sarl', 'gi',
        'comm_inf_180k', 'potentiel_agent', 'recodifications', 'postes_ag', 'embauches_commerciaux',
        'agents', 'wallets', 'point_de_vente', 'mandats_gestion'
      ]
    }
  },

  watch: {
    date_projetee_1: {
      handler (newVal, oldVal) {
        if (newVal) {
          this.fetchComptage(newVal).then(result => { this.comptage_1 = result })
        }
      },
      immediate: true
    },
    date_projetee_2: {
      handler (newVal, oldVal) {
        if (newVal) {
          this.fetchComptage(newVal).then(result => { this.comptage_2 = result })
        }
      },
      immediate: true
    }
  },

  mounted () {
    this.$store.commit('pages/setPageTitle', this.$t('global.per.comptage'))
  },

  methods: {
    fetchComptage (date_projetee) {
      let params = { date_projetee: this.$formatDate(date_projetee, 'YYYY-MM-DD') }
      // this.filters.forEach(filter => { params[filter.id] = filter.value })

      this.$q.loading.show({ message: 'Comptage des cibles en cours. Merci de patienter celui-ci peut prendre plusieurs minutes.' })

      return this.$axios.get('/api/comptage', { params })
        .then(response => response.data)
        .then(result => result.metrics || result)
        .catch(error => { console.log(error) })
        .finally(() => { this.$q.loading.hide() })
    },

    exportComptage () {
      let params = {
        first_date: this.$formatDate(this.date_projetee_1, 'YYYY-MM-DD'),
        second_date: this.$formatDate(this.date_projetee_2, 'YYYY-MM-DD')
      }

      this.$axios.get('/api/comptage/export', {
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'text/csv' },
        params
      }).then(response => {
        download(response.data, `comptage_${this.$formatDate(this.date_projetee_1, 'YYYY-MM-DD')}_${this.$formatDate(this.date_projetee_2, 'YYYY-MM-DD')}.csv`)
      }).catch(this.$notifyError)
    },

    exportComptageDetails (date_projetee) {
      let params = { date_projetee: this.$formatDate(date_projetee, 'YYYY-MM-DD') }

      this.$axios.get('/api/comptage/export/details', {
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'text/csv' },
        params
      }).then(response => {
        download(response.data, `comptage_${this.$formatDate(this.date_projetee_1, 'YYYY-MM-DD')}_${this.$formatDate(this.date_projetee_2, 'YYYY-MM-DD')}.csv`)
      }).catch(this.$notifyError)
    },

    isTitleItem (column) {
      return ['entites', 'agents', 'wallets'].indexOf(column) >= 0
    },

    getComptageDiff (column) {
      const val_1 = (this.comptage_1 && this.comptage_1[column]) || 0
      const val_2 = (this.comptage_2 && this.comptage_2[column]) || 0

      return val_2 || val_1 ? val_2 - val_1 : '-'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .title-item
    font-size: 1.2rem
    border-top: 1px solid $grey-4
    min-height: 4.0rem
</style>
